import { Typography } from "@mui/material";
import React, { useEffect, useState, useRef, memo } from "react";
import GaugeChart from "react-gauge-chart";

const ModelName = (props) => {
  const data = props.data;
  const label = data?.label;
  const confidence = parseFloat(data?.confidence.toFixed(2));
  const windowWidth = useRef(window.innerWidth);
  const windowHeight = useRef(window.innerHeight);
  const [serial, setSerial] = useState("");
  const [labelData, setLabelData] = useState("");

  var labels = [
    {
      name: "BrakePad",
      serialNo: "E28038212000",
    },
    {
      name: "ElectricalCable",
      serialNo: "E28038212001",
    },
    {
      name: "EmergencyStopButton",
      serialNo: "E28038212002",
    },
    {
      name: "HydraulicPumps",
      serialNo: "E28038212003",
    },
    {
      name: "LimitSwitches",
      serialNo: "E28038212004",
    },
    {
      name: "PulleyBlocks",
      serialNo: "E28038212005",
    },
    {
      name: "SparePartRacks",
      serialNo: "E28038212006",
    },
    {
      name: "Tyres",
      serialNo: "E28038212007",
    },
    {
      name: "WheelBearings",
      serialNo: "E28038212008",
    },
    {
      name: "WheelRims",
      serialNo: "E28038212009",
    },
    {
      name: "WinchMotor",
      serialNo: "E28038212010",
    },
    {
      name: "WireRopes",
      serialNo: "E28038212011",
    },
    {
      name: "Hooks",
      serialNo: "E28038212012",
    },
  ];

  useEffect(() => {
    if (parseFloat(data?.confidence.toFixed(2)) >= 0.9) {
      setLabelData(data.label);
      props.setmodelname(data.label);
     labels.map((item,index) => {
      console.log("item...",item)
      if(data.label === item.name){
        setSerial((item.serialNo))
      } 
      })

    } else {
      setLabelData("No Matches Found");
      props.setmodelname("No Matches Found");
      setSerial((""))
    }
  }, [props.data]);
  return (
    <div>
      <Typography
        sx={{ fontWeight: "800", fontSize: windowWidth.current / 25, fontFamily: "monospace" }}
      >
        Asset Name :{" "}
        {labelData == "No Matches Found" ? "No Matches Found" : label}
      </Typography>
      <div style={{ display: "flex", flexDirection: "row", marginTop: "5%"}}></div>
      <Typography
        sx={{ fontWeight: "800", fontSize: windowWidth.current / 25 }}
      >
        Confidence Level : {confidence}
      </Typography>
      <div style={{ display: "flex", flexDirection: "row", marginTop: "5%"}}></div>
      <Typography
        sx={{ fontWeight: "800", fontSize: windowWidth.current / 25 }}
      >
        S.No : {serial}
      </Typography>
    </div>
  );
};
export default memo(ModelName);
